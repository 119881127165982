<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.courier"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      prop="driver"
                      :label="columns.driver.title"
                      >
                        <el-input
                            :placeholder="columns.driver.title"
                            v-model="form.driver"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <crm-phone-input
                          :inputValue="form.phone" 
                          v-model="form.phone"
                          @getPhoneStatus="getPhoneStatus"
                          :form_prop="'phone'"
                          :country_code="form.phone_country_code"
                      ></crm-phone-input>
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <el-form-item
                      prop="transport_id"
                      :label="columns.transport_id.title"
                      >
                        <select-transport
                          :size="'medium'"
                          :placeholder="columns.transport_id.title"
                          :id="form.transport_id"
                          v-model="form.transport_id"
                        >
                        </select-transport>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="transport_number"
                      :label="columns.transport_number.title"
                      >
                        <el-input
                          :size="'medium'"  
                          type="text"
                          placeholder="00.A.000.AA"
                          v-model="form.transport_number"
                          v-mask="transportNumberV1"
                          masked="true"
                          clearable
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    
                    <el-col :span="12">
                      <el-form-item
                      prop="email"
                      :label="columns.email.title"
                      >
                        <el-input
                            :placeholder="columns.email.title"
                            v-model="form.email"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectTransport from "@/components/inventory/select-transport";

export default {
  mixins: [form, drawer],
  components: {
      selectTransport
  },
  data() {
    return {
      is_phone_valid: false,
      transportNumberV1: {
        mask: ["99._  ", "99.999.AAA", '99.A.999.AA'],
        tokens: {
          '9': '[0-9]',
          '_': { pattern: /[a-dA-D0-9]/, transform: v => v.toLocaleUpperCase() },
          'A': { pattern: /[a-dA-D]/, transform: v => v.toLocaleUpperCase() }
        }
      },
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "couriers/rules",
          model: "couriers/model",
          columns: "couriers/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "couriers/store",
      }),
      getPhoneStatus({is_valid, countryCode}){
          this.is_phone_valid = is_valid;
          this.form.phone_country_code = countryCode;
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
